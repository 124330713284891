import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Emoji from './emoji';

function Search({ destinations }) {
	const navigate = useNavigate();
	const [input, setInput] = useState("");
	const [suggestions, setSuggestions] = useState([])
	const [select, setSelect] = useState("");

	const handleChange = (e) => {
		//console.log("destinations: ", destinations);
		let value = e.target.value;
		let matches = [];
		if (value.length >= 1) {
			const regex = new RegExp(`${value}`, "gi");
			//console.log("regex", regex);
			matches = destinations.filter((item) =>
			(regex.test(item.city) ||
				regex.test(item.country) ||
				regex.test(item.state) ||
				regex.test(item.id)));
		}
		setSuggestions(matches);
		setInput(value)
	}

	const selectValue = (e, item) => {
		e.preventDefault();
		//console.log(item)
		let st = `${item.city} (${item.id})`
		setSelect(item.id.toLowerCase())
		setSuggestions([]);
		setInput(st)
	}

	const go = (e) => {
		e.preventDefault();
		if (!select) {
			return
		}
		//let f = destinations.filter((item) => item.id === select)[0];
		//console.log("going to: ", f)
		navigate(`/destination/${select}`)
	}

	const goRandom = (e) => {
		e.preventDefault();
		let f = destinations[Math.floor(Math.random() * destinations.length)];
		//console.log("going to: ", f)
		navigate(`/destination/${f.id}`)
	}

	return (
		<>
			<div className="input-group" >
				<input className='form-control form-control-lg'
					list="suggestions"
					type="text"
					placeholder="search destinations"
					value={input}
					onChange={handleChange}
				/>
				<div className="input-group-append btn-group" role="group">
					<button className="btn btn-lg btn-primary" type="button" onClick={(e) => go(e)}>Go!</button>
					<button className="btn btn-lg btn-secondary" type="button" onClick={(e) => goRandom(e)}>Random</button>

				</div>
			</div>
			{
				suggestions.length > 0 ? (
					<div className="list-group position-absolute" >
						{
							suggestions.map(item => {
								return (
									<a href="/"
										onClick={(e) => selectValue(e, item)}
										className='list-group-item'
										key={item.id} >
										{item.city}{item.state && `, ${item.state}`}, {item.country} ({item.id})
										&nbsp;
										<Emoji label={item.country} emoji={item.flag} />
									</a>
								)
							})
						}
					</div>
				) : null
			}
		</>
	)
}

export default Search;