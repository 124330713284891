import React from 'react';
import Emoji from './emoji';
import { Link } from 'react-router-dom';
import { toLocalTime, toLocalDate } from './localTimeString';

const formatter = new Intl.NumberFormat(`en-US`, { currency: `USD`, style: 'currency' });
const getDestination = (tiaCode, destinations) => {
	if (destinations) {
		for (let i = 0; i < destinations.length; i++) {
			if (destinations[i].id === tiaCode) {
				return destinations[i]
			}
		}
	}
	return null;
}

const ShowDeal = ({ deal, destinations }) => {
	deal = deal[1];
	const destInfo = getDestination(deal.arrival_airport, destinations);
	return (
		<>
			<h4>
				<Link to={`/destination/${deal.arrival_airport}`} className="destinationLink"><Emoji text={destInfo.country} emoji={destInfo.flag} />
					&nbsp;
					{destInfo.city}, {destInfo.country}
				</Link>
			</h4>
			<h3>
				<span className="text-success">
					{formatter.format(deal.price)}
				</span> / &nbsp;
				<span className="text-secondary">
					<del>{formatter.format(destInfo.average_price)}</del>
				</span>
			</h3>
			<h5 className="text-blue">Sample Booking Date: {toLocalDate(deal.depart_date)}</h5>
			<p className="font-weight-bold text-muted">Date found: {toLocalTime(deal.date_found)}</p>
		</>
	)

}

function DealBank({ deals, destinations }) {
	return (
		<>
			<h1>Top Deals</h1>
			<div className="row">
				{deals.map((item, index) =>
					<div className="col-md-4" key={index}>
						<div className="card m-2 p-2 rounded-3">
							<ShowDeal deal={item} destinations={destinations} />
						</div>
					</div>
				)}
			</div>
		</>
	)
}



function BestDeals({ deals, destinations }) {
	return (
		<>
			<h2 className="text-center mt-4">
				<Emoji text="best" emoji="🙌" />
				&nbsp;
				Best Flights From PDX!
			</h2>
			<p className="lead">Looking to book a trip right now?
				These are the very best flights from PDX to the rest of the world.
				We do not sell flights.
				You can book them right now by searching on Google Flights for exact dates and booking
				directly with the airlines. We have included one sample date, but there are more flights availble than what you see here.
				&nbsp;
				<Emoji text="fly away" emoji="🛫" />
			</p>
			<div className="alert alert-info text-center" role="alert">
				<Emoji text="note" emoji="🗒" /> &nbsp;
				These deals change frequently, Don't miss them! Be notified immediately
				when you <Link to="/settings" className="alert-link">Sign up</Link> with us now!
				&nbsp;
				<Emoji text="note" emoji="🗒" />
			</div>
			<DealBank deals={deals} destinations={destinations} />
		</>
	)

}

export default BestDeals;