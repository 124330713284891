import React from 'react';
import NavBar from './navbar';
import { Link } from 'react-router-dom';
import Emoji from './emoji';
import { useSelector } from 'react-redux';
import Footer from './footer';



function About() {
	const subscriptions = useSelector(state => state.API.subscriptions);

	return (
		<>
			<NavBar selected="about" />
			<div className="container min-vh-100">
				<header>
					<div className="lead mt-3">
						<Emoji label="hi" emoji="👋🏽" />Hi, My name is Val and I live in Portland, OR.
						<div className="my-3">
							<img src="/images/vallard-300.png" alt="vallard" />
						</div>

						<Emoji label="usa" emoji="🇺🇸" /> My wife and I have 4 kids and we've found some amazing
						deals from flights out of PDX.
						<div className="my-3">
							<img src="/images/pdx-family-300.png" alt="family" />
						</div>

						<Emoji label="takeoff" emoji="🛫" />
						We have traveled with them everywhere!
						<div className="my-3 mx-auto">
							<img className="mx-3" src="/images/stairway-300.png" alt="hawaii" />
							<img className="mx-3" src="/images/colloseum-300.png" alt="rome" />
							<img className="mx-3" src="/images/chichenitza-300.png" alt="cancun" />
						</div>
					</div>
					<p className="lead">
						<Emoji label="code" emoji="🧑‍💻" />I also happen to be a software engineer
						with some data science skills.
						I put this site together to help me find the best flight deals out of PDX.
					</p>
					<p className="lead">
						<Emoji label="alert" emoji="🚨" />I get alerts when they become available.
						I can also alert you as well.
						There are other sites that do this, but none of them cater to PDX like I do.
					</p>
					<p className="lead">
						<Emoji label="detective" emoji="🕵️‍♀️" />
						I've put together some statistics and helful hints on this site to help you find the best deals.
						So even if you don't want to sign up for alerts, I hope you can still get value out of this site.
					</p>
					<p className="lead">
						<Emoji label="hot-cocoa" emoji="☕️" />
						And if you feel inclined to sign up, think of it as donating a cup of coffee once a month
						for helping you book a great deal. I promise you, you will get back 100% your investment
						if you book a deal.
					</p>
					{subscriptions && subscriptions.length > 0 ?
						null
						:
						<div className="my-3 alert alert-info text-center" role="alert">
							<Link to="/signup" className="alert-link">Sign up Now!</Link>
						</div>
					}
				</header>


			</div>
			<Footer />
		</>
	)
}

export default About;