import React from 'react';
import NavBar from './navbar';
import Emoji from './emoji';



function PDX() {

    return (
        <>
            <NavBar selected="about" />
            <div className="container">
                <div className="lead mt-3">
                    <h1><Emoji label="hi" emoji="🗺" /> PDX Airport Guide</h1>
                    <p>PDX constantly rates high as one of the best airports in the world.  We are lucky it is in our own backyard and our launch
                        pad to so many places.  This guide talks about getting the most out of your departure out of PDX. </p>

                    <h2>Tip 1: Chase Saphire Reserve Credit Card.</h2>
                    <p>I am not paid, nor sponsored by this credit card, but I have had it for the last 8 years and let me tell you,
                        you get the most out of it at PDX.  So I highly recommend this card.  Here's why:
                    </p>
                    <h3>Global Entry/TSA Precheck</h3>
                    <p>
                        The card pays the full cost of getting your <a href="https://www.cbp.gov/travel/trusted-traveler-programs/global-entry">Global Entry</a> card.
                        Global Entry automatically gets you to TSA precheck for every trip you make.  No more taking off your shoes or taking your computer out
                        of your carry-on.
                    </p>
                    <h3>Priority Pass</h3>
                    <p>As part of the card you get the Priority Pass. At PDX this means you get $28 of credit to spend at Capers Cafe. If
                        you are traveling with a guest then you get $58.  If you have the credit card and your spouse has the credit card
                        and if you are both traveling with guests you get $116 to spend at the airport in food.
                        If you are like me and traveling with a family of 6, this makes for a fun pre-trip food hunt.


                    </p>

                </div>
            </div>
        </>
    )
}

export default PDX;