import React from "react";
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import Home from '../components/home';
import About from '../components/about';
import Subscribe from '../components/subscribe';
import NotFound from '../components/notfound';
import Destination from '../components/destination';
import Confirmation from '../components/confirm';
import SignIn from '../components/signin';
import SignUp from "../components/signup";
import Privacy from "../components/privacy";
import Forgot from "../components/forgot";
import Reset from "../components/reset";
import PDX from "../components/pdx";
import Today from "../components/today";
//import { useSelector } from "react-redux";


const PrivateOutlet = () => {
    //const isAuthenticated = useSelector(state => state.API.isAuthenticated);
    const token = sessionStorage.getItem('ttw-jwt')
    console.log("token: ", token)
    return token ? <Outlet /> : <Navigate to="/signin" />;
}


const allRoutes = () =>
    <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/auth/confirm" element={<Confirmation />} />
        <Route exact path="/auth/reset" element={<Reset />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/today-deals" element={<Today />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/pdx" element={<PDX />} />
        <Route exact path="/destination/:tia" element={<Destination />} />
        <Route exact path="/privacy" element={<Privacy />} />
        {/* private routes */}
        <Route exact path="/settings" element={<PrivateOutlet />}>
            <Route exact path="/settings" element={<Home />} />
        </Route>
        <Route exact path="/settings/subscribe" element={<PrivateOutlet />}>
            <Route exact path="/settings/subscribe" element={<Subscribe />} />
        </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>

export default allRoutes