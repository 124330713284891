import {
    FETCH_API,
    FETCH_HOME,
    FETCH_DESTINATION,
    SEND_DATA,
    NEW_SIGNUP_DATA_TYPE,
    GOT_RESPONSE,
    ERROR,
    FETCH_PRICES,
    CREATE_SUBSCRIPTION_DATA,
    FETCH_SUBSCRIPTIONS,
    SEND_VERIFICATION_CODE,
    SIGNIN_DATA_TYPE,
    GET_CURRENT_SESSION,
    PREFERENCES_FETCH,
    SET_TOKEN,
    FETCH_USER,
    DELETE_API,
    DELETE_SUBSCRIPTION,
    RESEND_VERIFICATION_CODE,
    SET_USER,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    DID_PURCHASE_SUBSCRIPTION,
    DISMISS_MESSAGE,
} from './constants';

const initState = {
    loading: false,
    home: {},
    bestDealsRightNow: [],
    allDestinations: [],
    destination: {},
    user: {},
    subscriptionPrices: [],
    subscriptionData: {},
    subscriptions: [],
    error: null,
    token: null,
    preferences: {},
    isAuthenticated: false,
    message: "",
}

const API = (state = initState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            // if token is cleared, clear everything else. 
            if (!action.token) {
                sessionStorage.removeItem('ttw-jwt');
                return {
                    ...state, token: action.token,
                    isAuthenticated: false,
                    preferences: {},
                    user: {},
                    subscriptions: [],
                    subscriptionPrices: []
                };
            } else {
                return { ...state, token: action.token, isAuthenticated: action.token !== null };
            }
        case DISMISS_MESSAGE:
            return { ...state, message: null };
        case SET_USER:
            return { ...state, user: { "email": action.email } }
        case PREFERENCES_FETCH:
            return { ...state, loading: true, error: null, preferenes: {} };
        case FETCH_API:
            return { ...state, loading: true, destination: {}, error: null };
        case DELETE_API:
            return { ...state, loading: true, error: null, message: "Subscription Cancelled.  So sad." };
        case SEND_DATA:
            return { ...state, loading: true, error: null, message: null };
        case GOT_RESPONSE:
            if (action.responseType === FETCH_HOME) {
                //console.log("action.response:", action.response)
                return {
                    ...state, loading: false, error: null,
                    home: action.response,
                    bestDealsRightNow: action.response.best_deals_right_now,
                    allDestinations: action.response.destinations
                }
            } else if (action.responseType === FETCH_DESTINATION) {
                return { ...state, loading: false, error: null, destination: action.response }
            } else if (action.responseType === NEW_SIGNUP_DATA_TYPE) {
                return { ...state, loading: false, error: null, user: action.response }
            } else if (action.responseType === SIGNIN_DATA_TYPE) {
                return { ...state, loading: false, error: null, isAuthenticated: true, user: action.response }
            } else if (action.responseType === FETCH_PRICES) {
                return { ...state, loading: false, error: null, subscriptionPrices: action.response.prices }
            } else if (action.responseType === FETCH_SUBSCRIPTIONS) {
                var subscriptions = action.response.subscriptions;
                return { ...state, loading: false, error: null, subscriptions: subscriptions.data }
            } else if (action.responseType === CREATE_SUBSCRIPTION_DATA) {
                return { ...state, loading: false, error: null, subscriptionData: action.response }
            } else if (action.responseType === SEND_VERIFICATION_CODE) {
                var error = null;
                var message = "Account Verified!  You can now sign in!"
                if (action.response.detail) {
                    error = action.response.detail;
                    error = null;
                }
                return { ...state, loading: false, error: error, message: message }
            } else if (action.responseType === GET_CURRENT_SESSION) {
                return { ...state, token: action.response }
            } else if (action.responseType === PREFERENCES_FETCH) {
                return { ...state, preferences: action.response, loading: false, error: null }
            } else if (action.responseType === FETCH_USER) {
                return { ...state, user: action.response, loading: false, error: null }
            } else if (action.responseType === DELETE_SUBSCRIPTION) {
                return { ...state, loading: false, error: null }
            } else if (action.responseType === RESEND_VERIFICATION_CODE) {
                return { ...state, loading: false, error: null, message: "Reverification code sent" }
            } else if (action.responseType === RESET_PASSWORD) {
                return { ...state, loading: false, error: null, message: "If this email was registered, we have sent an email for you to reset your password." }
            } else if (action.responseType === CHANGE_PASSWORD) {
                return { ...state, loading: false, error: null, message: "Password changed! You can now log in with the new password." }
            } else if (action.responseType === DID_PURCHASE_SUBSCRIPTION) {
                return { ...state, loading: false, error: null, message: "Subscription Successfully purchased!" }
            } else {
                console.log("uncaptured...", action.responseType)
            }

            // unknown response type. 
            return { ...state, loading: false, error: "unimplemented response" }
        case ERROR:
            return { ...state, loading: false, error: action.error }
        default:
            return { ...state };
    }
};

export default API;
