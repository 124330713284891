import React, { useState, useEffect } from 'react';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { sendData } from '../redux/actions';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NavBar from './navbar';
import Emoji from './emoji';
import { CHANGE_PASSWORD } from '../redux/api/constants';

function Reset() {
	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const error = useSelector(state => state.API.error);
	const message = useSelector(state => state.API.message);
	const search = useLocation().search;


	useEffect(() => {
		var pEmail = new URLSearchParams(search).get('to');
		pEmail = decodeURIComponent(pEmail);
		const pToken = new URLSearchParams(search).get('code')
		console.log('decoded email: ', pEmail);
		console.log('decoded code: ', pToken);

		if (pEmail && pToken) {
			setEmail(pEmail);
			setToken(pToken);
		}
	}, [search])

	const validateAndSubmit = (e) => {
		e.preventDefault();
		setPasswordError(null)
		console.log("token: ", token);
		console.log("email: ", email);
		console.log("password: ", password);
		if (token && password && email) {
			if (!validator.isLength(password, { min: 8 })) {
				setPasswordError("password should be at least 8 characters")
				return;
			}
			dispatch(sendData(CHANGE_PASSWORD, { email: email, password: password, code: token, 'navigate': navigate }));
		} else {
			setPasswordError('Missing token or valid email... how did you even get here?')
		}
	}

	return (
		<>
			<NavBar error={error} message={message} />
			<div className="container">
				<form onSubmit={validateAndSubmit}>
					<fieldset>
						<legend className="text-center">Reset Password for {email}</legend>
						<div className="mb-3 col-md-4 offset-md-4">
							<input
								className={passwordError ? "my-2 form-control form-control-lg is-invalid" :
									"form-control form-control-lg my-2"}
								type="password"
								placeholder="new password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								aria-label="enter password" />
							<div className="invalid-feedback">
								{passwordError}
							</div>
						</div>
						<div className="mt-3 text-center" >
							<button type="submit" className="btn btn-lg btn-primary mx-auto">Reset</button>
						</div>
						<div className="mt-3 text-center text-muted" >
							<Emoji text="no account" emoji="😢" /> No Account? <Link to="/signup" >Sign Up!</Link> <Emoji text="sign up" emoji="😃" /> |
							&nbsp;<Link to="/signin" >Sign In!</Link> <Emoji text="sign in" emoji="😃" />
						</div>
					</fieldset>
				</form>
			</div>
		</>
	)
}

export default Reset;