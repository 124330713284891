import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchAPI } from '../redux/actions';
import { FETCH_DESTINATION } from '../redux/api/constants';
import NavBar from './navbar';
import Emoji from './emoji';
import { useParams } from "react-router-dom";
import { toLocalTime, toLocalDate } from './localTimeString';
import Footer from './footer';

function Average({ averagePrice, updatedAt }) {
    return (
        <>
            {averagePrice !== "N/A" &&
                < div className="py-3">
                    <h2>
                        <Emoji label="so average" emoji="😐" />&nbsp;Average round trip flight:
                        <span className="text-warning">
                            ${averagePrice}
                        </span>
                    </h2>
                </div>
            }
        </>
    )
}

const nDaysFromDate = (dateString, numberOfDays) => {
    // increment date by 4 days
    var nDays = new Date(dateString);
    nDays.setDate(nDays.getDate() + numberOfDays);
    const fmt = nDays.getFullYear() + "-" + (nDays.getMonth() + 1).toString().padStart(2, 0) + "-" + nDays.getDate().toString().padStart(2, 0)
    return fmt
}

const dateFromDateTime = (dateTime) => {
    return dateTime.split(" ")[0]
}

function TodaysBest({ bestToday }) {
    return (
        <div>
            <h2>
                <Emoji label="checkered flag" emoji="🏁" />&nbsp;Cheapest Flights
            </h2>
            <ul className="list-unstyled">
                {bestToday.map((deal, index) => (
                    <li key={index}>
                        {index === 0 && <Emoji label="award" emoji="🥇" />}
                        {index === 1 && <Emoji label="award" emoji="🥈" />}
                        {index === 2 && <Emoji label="award" emoji="🥉" />}
                        {index === 3 && <Emoji label="award" emoji="🎖" />}
                        {index === 4 && <Emoji label="award" emoji="🎖" />}
                        {index === 5 && <Emoji label="award" emoji="🎖" />}
                        <span className="text-success">${deal.price}</span>
                        &nbsp;
                        <b>Departure Date:</b> {toLocalDate(deal.depart_date)}
                        &nbsp;
                        <a href={`https://www.google.com/travel/flights?q=Flights%20to%20${deal.arrival_airport}%20from%20${deal.departure_airport}%20on%20${dateFromDateTime(deal.depart_date)}%20through%20${nDaysFromDate(deal.depart_date, 4)}`}
                            rel="noopener noreferrer"
                            target="_blank">Search On Google Flights</a>

                    </li>
                ))}
            </ul>
        </div>
    )
}



function BestEver({ best }) {
    return (
        <div>
            <h2><Emoji label="best" emoji="🏆" />&nbsp;Best Flight Ever</h2>
            <h3><span className="text-success">${best.price}</span> on {toLocalDate(best.depart_date)}</h3>
            {best.date_created &&
                <h6 className="text-muted">Date Found: {toLocalDate(best.date_created)}</h6>
            }
        </div>
    )
}

function Destination() {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAPI(FETCH_DESTINATION, "pdx", params.tia));
    }, [dispatch])
    const destInfo = useSelector(state => state.API.destination)
    const error = useSelector(state => state.API.error)
    const loading = useSelector(state => state.API.loading)

    return (
        <>
            <NavBar selected="home" error={error} />
            <header className="py-5">
                <div className="container pt-md-1 pb-md-4 min-vh-100">
                    {loading && <div className="text-center"><div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div></div>}
                    {(destInfo !== null && Object.entries(destInfo).length > 0 && !error) &&
                        <>
                            <h1>
                                <Emoji label={destInfo.country} emoji={destInfo.flag} />&nbsp;
                                {destInfo.city}{destInfo.state && ` ${destInfo.state}`}, {destInfo.country}
                                ({params.tia.toUpperCase()})
                            </h1>
                            {destInfo.updated_at &&
                                <h6 className="text-muted">Last Updated: {toLocalTime(destInfo.updated_at)}</h6>
                            }

                            <Average averagePrice={destInfo.average_price} />
                            {destInfo.current_best &&
                                <TodaysBest bestToday={destInfo.current_best} />
                            }
                            {(destInfo.best_ever && Object.keys(destInfo.best_ever).length > 0) &&
                                <BestEver best={destInfo.best_ever} />
                            }
                        </>
                    }
                </div>
            </header>
            <Footer />
        </>
    );
}

export default Destination;
