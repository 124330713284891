import moment from "moment";
import 'moment-timezone';


export function toLocalTime(dateString) {
    var localDate = moment.utc(dateString, "YYYY-MM-DD HH:mm:ss").tz('America/Los_Angeles').format('MMM Do @ h:mm A z');
    return localDate;
}

export function toLocalDate(dateString) {
    var localDate = moment.utc(dateString, "YYYY-MM-DD HH:mm:ss").tz('America/Los_Angeles').format('MMM Do, Y');
    return localDate;
}
