import React, { useEffect } from 'react';
import { apiError, fetchAPI, sendData } from '../redux/actions';
import { CREATE_SUBSCRIPTION_DATA, FETCH_PRICES } from '../redux/api/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const lookupToName = (lookup_key) => {
	if (lookup_key === "basic_alerts") {
		return "Basic Alerts";
	}
	return lookup_key;
}

const Plans = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchAPI(FETCH_PRICES));
	}, [])
	const navigate = useNavigate();
	const prices = useSelector(state => state.API.subscriptionPrices);
	const token = useSelector(state => state.API.token);
	const user = useSelector(state => state.API.user);

	const createSubscription = async (priceId) => {
		console.log("creating subscription")
		if (user && Object.entries(user).length > 0) {
			dispatch(sendData(CREATE_SUBSCRIPTION_DATA, {
				"price_id": priceId,
				"token": token,
				"navigate": navigate,
			}));
		} else {
			dispatch(apiError("User is not logged in.  This should never happen.  \\o/"))
		}
	}
	return (
		<div className="pt-md-1 pb-md-1">
			{prices && prices.map((price) => {
				return (
					<div className="" key={price.id}>
						<div className="text-secondary" key={price.id}>
							<h3>{lookupToName(price.lookup_key)}</h3>
							<h5>
								${(price.unit_amount / 100).toFixed(2)}/month
							</h5>

							<button className="btn btn-lg btn-primary" onClick={() => createSubscription(price.id)}>
								Sign Up
							</button>
						</div>
					</div>
				)
			})}
		</div>
	);
}

export default Plans;