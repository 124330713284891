import React, { useState, useEffect } from 'react';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { sendData } from '../redux/actions';
import { NEW_SIGNUP_DATA_TYPE } from '../redux/api/constants';
import config from '../config';
import { Link, useNavigate } from 'react-router-dom';
import Plans from './plans';

function SignUpButton() {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneNumberError, setPhoneNumberError] = useState("");
	const dispatch = useDispatch()
	const isAuthenticated = useSelector(state => state.API.isAuthenticated);
	const communicationMethod = useSelector(state => state.signup.communicationMethod);


	useEffect(() => {
		const script = document.createElement('script')
		script.src = `https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA_KEY}`
		document.body.append(script)
	}, []);


	const validateAndSubmit = (e) => {
		e.preventDefault();
		setEmailError(null)
		setPasswordError(null)
		setPhoneNumberError(null)

		const emailMethods = ['email', 'both'];
		const smsMethods = ['sms', 'both'];
		if (emailMethods.includes(communicationMethod) && !validator.isEmail(email)) {
			setEmailError("Email is not valid");
			return;
		}

		if (smsMethods.includes(communicationMethod) && !validator.isMobilePhone(phoneNumber)) {
			setPhoneNumberError('Please enter a valid phone number.')
			return;
		}
		if (emailMethods.includes(communicationMethod) && password === "") {
			setPasswordError('Password can not be empty.')
			return;
		}

		window.grecaptcha.ready(function () {
			window.grecaptcha.execute(config.RECAPTCHA_KEY, { action: 'submit' }).then(function (token) {
				// Send form value as well as token to the server
				dispatch(sendData(NEW_SIGNUP_DATA_TYPE, { phone: phoneNumber, email: email, password: password, token: token, navigate: navigate }));
				//history.push("/auth/confirm")
			});
		});

	}

	if (isAuthenticated) {
		return (<Plans />);
	}
	return (
		<form onSubmit={validateAndSubmit}>
			<fieldset>
				<legend className="text-center">Sign Up For Alerts!</legend>
				{(communicationMethod === 'sms' || communicationMethod === 'both') &&
					<div className="mb-3 col-md-4 offset-md-4">
						<input className={phoneNumberError ? "form-control form-control-lg is-invalid" :
							"form-control form-control-lg"}
							type="tel"
							placeholder="Phone Number"
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
							aria-label="enter phone number" />
						<div className="invalid-feedback">{phoneNumberError}</div>
					</div>
				}
				{(communicationMethod === 'email' || communicationMethod === 'both' || !communicationMethod) &&
					<div className="mb-3 col-md-4 offset-md-4">
						<input
							className={emailError ? "form-control form-control-lg is-invalid" :
								"form-control form-control-lg"}
							type="text"
							placeholder="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							aria-label="enter email" />
						<div className="invalid-feedback">
							{emailError}
						</div>
						<input
							className={passwordError ? "my-2 form-control form-control-lg is-invalid" :
								"form-control form-control-lg my-2"}
							type="password"
							placeholder="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							aria-label="enter password" />
						<div className="invalid-feedback">
							{passwordError}
						</div>
					</div>
				}
				<div className="mt-3 text-center" >
					<button type="submit" className="btn btn-lg btn-primary mx-auto">Sign Up!</button>
				</div>
				<div className="mt-3 text-center">
					Already Have an Account? <Link to="/signin">Sign In</Link>
				</div>
			</fieldset>
		</form>
	)
}

export default SignUpButton;