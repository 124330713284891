import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteAPI, sendData } from '../redux/actions';
import { CREATE_SUBSCRIPTION_DATA, DELETE_SUBSCRIPTION } from '../redux/api/constants';

const AccountSubscription = ({ subscription }) => {
	const token = useSelector(state => state.API.token);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const unsubscribe = (sid) => {
		dispatch(deleteAPI(DELETE_SUBSCRIPTION, { "token": token, "subscription": sid }))
	}

	const restartSubscription = (priceId) => {
		dispatch(sendData(CREATE_SUBSCRIPTION_DATA, {
			"price_id": priceId,
			"token": token,
			"navigate": navigate,
		}));
	}

	return (
		<div className="text-secondary">
			<h6>Status: <span className="text-success">
				{subscription.status}
			</span>
			</h6>
			<h6>Renews: &nbsp;
				<span className="text-success">
					{(new Date(subscription.current_period_end * 1000).toDateString())}
				</span>
			</h6>
			{subscription.status === "active" &&
				<>
					<button type="button" className="btn btn-danger float-end" data-bs-toggle="modal" data-bs-target={`#subscription-${subscription.id}`}>
						Cancel Subscription
					</button>
					<div className="modal fade" id={`subscription-${subscription.id}`} tabIndex="-1" aria-labelledby={`subscription-${subscription.id}Label`} aria-hidden="true">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id={`subscription-${subscription.id}Label`}>Cancel Subscription</h5>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div className="modal-body">
									We are sad to see you go!  Are you sure?
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={(e) => unsubscribe(subscription.id)}>Yes</button>
								</div>
							</div>
						</div>
					</div>
				</>
			}
			{subscription.status === "incomplete_expired" &&
				<button type="buttn" className="btn btn-primary" onClick={(e) => { restartSubscription(subscription.plan.id) }}>Subscribe</button>
			}
		</div>

	)
}

const Subscriptions = ({ subscriptions }) => {
	if (!subscriptions) {
		return '';
	}


	return (
		<div>
			<div id="subscriptions">
				{subscriptions.map(s => {
					return <AccountSubscription key={s.id} subscription={s} />
				})}
			</div>
		</div>
	);
}

export default Subscriptions;