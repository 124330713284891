export const FETCH_API = 'FETCH_API';
export const FETCH_HOME = 'FETCH_HOME';
export const FETCH_DESTINATION = 'FETCH_DESTINATION';
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const GOT_RESPONSE = 'GOT_RESPONSE';
export const SEND_DATA = 'SEND_DATA';
export const ERROR = 'ERROR';

export const SET_USER = 'SET_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD'; // for when they request to change password and we send an email to them.
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'; // for when they enter the new password.

export const NEW_SIGNUP_DATA_TYPE = 'NEW_EMAIL_DATA_TYPE';
export const PREFERENCES_FETCH = 'PREFERENCES_FETCH';
export const SIGNIN_DATA_TYPE = 'SIGNIN_DATA_TYPE';
export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';
export const CREATE_SUBSCRIPTION_DATA = 'CREATE_SUBSCRIPTION_DATA';
export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE';
export const FETCH_PRICES = 'FETCH_PRICES';
export const FETCH_USER = 'FETCH_USER';
export const DID_PURCHASE_SUBSCRIPTION = 'DID_PURCHASE_SUBSCRIPTION';

/* delete constants */
export const DELETE_API = 'DELETE_API';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';

export const GET_CURRENT_SESSION = 'GET_CURRENT_SESSION';
export const CURRENT_AUTHENTICATED_USER = 'CURRENT_AUTHENTICATED_USER';
export const SET_TOKEN = 'SET_TOKEN';