import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchAPI } from '../redux/actions';
import { FETCH_HOME } from '../redux/api/constants';
import NavBar from './navbar';
import BestDeals from './bestDeals';
import Footer from './footer';

function Today() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAPI(FETCH_HOME));
    }, [dispatch])

    const allDestinations = useSelector(state => state.API.allDestinations);
    const bestDealsRightNow = useSelector(state => state.API.bestDealsRightNow);
    const error = useSelector(state => state.API.error);

    return (
        <>
            <NavBar selected="today" error={error} />
            {bestDealsRightNow.length > 0 &&
                <header>
                    <div className="container pt-xs-1 pb-xs-3 min-vh-100">
                        <BestDeals deals={bestDealsRightNow} destinations={allDestinations} />
                    </div>
                </header>
            }
            <Footer />
        </>
    );
}

export default Today;
