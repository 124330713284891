import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from './api';


import {
    FETCH_API,
    FETCH_DESTINATION,
    SEND_DATA,
    NEW_SIGNUP_DATA_TYPE,
    FETCH_PRICES,
    CREATE_SUBSCRIPTION_DATA,
    FETCH_SUBSCRIPTIONS,
    SEND_VERIFICATION_CODE,
    SIGNIN_DATA_TYPE,
    GET_CURRENT_SESSION,
    PREFERENCES_FETCH,
    FETCH_USER,
    DID_PURCHASE_SUBSCRIPTION,
    DELETE_API,
    DELETE_SUBSCRIPTION,
    RESEND_VERIFICATION_CODE,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
} from './constants';


import {
    gotResponse,
    apiError,
    fetchAPI,
} from './actions';

/* checks if there is a saved auth token. */
function* getCurrentSession() {
    const jwt = sessionStorage.getItem('ttw-jwt');
    yield (gotResponse(GET_CURRENT_SESSION, jwt));
}

function* getAPI({ fetchType, fetchVar1, fetchVar2 }) {
    var path = "/home"
    var headers = null;
    if (fetchType === FETCH_DESTINATION) {
        path = "/destinations/" + fetchVar1 + "/" + fetchVar2
    } else if (fetchType === FETCH_PRICES) {
        //console.log("fetching prices");
        path = "/payment/prices"
    } else if (fetchType === FETCH_SUBSCRIPTIONS) {
        headers = {
            "Authorization": "Bearer " + fetchVar1,
            "Content-Type": "application/json",
        }
        path = "/payment/subscriptions"
    } else if (fetchType === PREFERENCES_FETCH) {
        headers = {
            "Authorization": "Bearer " + fetchVar1,
            "Content-Type": "application/json",
        }
        path = "/user/preferences"
        //console.log("set fetching preferences");
    } else if (fetchType === FETCH_USER) {
        headers = {
            "Authorization": "Bearer " + fetchVar1,
            "Content-Type": "application/json",
        }
        path = "/user"
    }
    try {
        const response = yield call(api.get, {
            path: path,
            headers: headers,
        });
        //console.log("API Response: ", response);
        yield put(gotResponse(fetchType, response))
    } catch (err) {
        if (err.name === 'TypeError' || err.name === 'NetworkError') {
            yield put(apiError("Error connecting to backend API."));
            return
        } else if (err.name === 'Error') {
            yield put(apiError(err.message));
        }
        yield put(apiError(err));
    }
}

function* sendAPI(action) { //{ dataType, data }) {
    var path = "";
    var data = null;
    var headers = null;
    var formData = null;
    if (action.dataType === NEW_SIGNUP_DATA_TYPE) {
        // uses the default path of "/users"
        //console.log("data: ", action.data)
        data = {
            "email": action.data.email,
            "password": action.data.password,
            "recaptcha_token": action.data.token,
        };
        path = "/auth/signup";
    } else if (action.dataType === RESEND_VERIFICATION_CODE) {
        data = {
            "email": action.data.email,
            "code": ""
        };
        path = "/auth/reconfirm";
    } else if (action.dataType === SEND_VERIFICATION_CODE) {
        console.log(SEND_VERIFICATION_CODE, action.data);
        data = {
            "code": action.data.code,
            "email": action.data.email,
        }
        path = "/auth/verify";
        // RESET_PASSWORD will start the process of resetting the password by sending
        // a verification code to the user's email.
    } else if (action.dataType === RESET_PASSWORD) {
        data = {
            "email": action.data.email,
        }
        path = "/auth/reset";
        // CHANGE_PASSWORD will change the user's password on the backend.  We assume
        // the user already got the code by using RESET_PASSWORD first. 
    } else if (action.dataType === CHANGE_PASSWORD) {
        data = {
            "email": action.data.email,
            "code": action.data.code,
            "password": action.data.password,
        }
        path = "/auth/changepassword";

    } else if (action.dataType === SIGNIN_DATA_TYPE) {
        //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
        var details = {
            "username": action.data.email,
            "password": action.data.password,
            "client_secret": action.data.token,
        }
        formData = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formData.push(encodedKey + "=" + encodedValue);
        }
        formData = formData.join("&");
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
        path = "/auth/login";

    } else if (action.dataType === CREATE_SUBSCRIPTION_DATA) {
        path = "/payment/subscription";
        headers = {
            "Authorization": "Bearer " + action.data.token,
            "Content-Type": "application/json",
        }
        data = {
            "price_id": action.data.price_id,
        };
    } else if (action.dataType === DID_PURCHASE_SUBSCRIPTION) {
        path = "/payment/confirm";
        headers = {
            "Authorization": "Bearer " + action.data.token,
            "Content-Type": "application/json",
        }
        data = {
            "paymentIntent": action.data.paymentIntent
        }
    }
    try {
        const response = yield call(api.send, {
            path: path,
            data: data,
            headers: headers,
            formData: formData
        })
        if (action.dataType === NEW_SIGNUP_DATA_TYPE) {
            if (response.detail) {
                //console.log("error signing up user: ", response.detail);
                yield put(apiError(response.detail));
                return;
            } else {
                //console.log("pushing to new url")
                action.data.navigate("/auth/confirm");
            }
        } else if (action.dataType === SEND_VERIFICATION_CODE) {
            console.log(SEND_VERIFICATION_CODE, response);
            if (response.status === "ok") {
                action.data.navigate("/signin")
            } else if (response.detail) {
                yield put(apiError(response.detail))
                return;
            }
        } else if (action.dataType === SIGNIN_DATA_TYPE) {
            if (response.access_token) {
                //console.log(response);
                sessionStorage.setItem('ttw-jwt', response.access_token)
            } else {
                yield put(apiError(response.detail))
                return
            }
        } else if (action.dataType === CREATE_SUBSCRIPTION_DATA) {
            if (response.clientSecret) {
                action.data.navigate("/settings/subscribe");
            } else {
                yield put(apiError(response.detail));
                return;
            }
        } else if (action.dataType === DID_PURCHASE_SUBSCRIPTION) {
            //console.log("subscription response: ", response);
            action.data.navigate("/");
        } else if (action.dataType === CHANGE_PASSWORD) {
            if (response.status === "ok") {
                action.data.navigate("/signin")
            } else if (response.detail) {
                yield put(apiError(response.detail))
                return;
            }
        }
        yield put(gotResponse(action.dataType, response))
    } catch (err) {
        console.log("We got an error: ", err);
        yield put(apiError(err));
    }
}

function* deleteAPI(action) {
    var path = "/"
    var data = {}
    var headers = {
        "Authorization": "Bearer " + action.data.token,
        "Content-Type": "application/json",
    }
    if (action.dataType === DELETE_SUBSCRIPTION) {
        path = "/payment/subscription";
        data = {
            "subscription_id": action.data.subscription,
        };
    } else {
        console.log("data type: ", action.dataType);
    }
    try {
        const response = yield call(api.delete, {
            path: path,
            data: data,
            headers: headers,
        })
        console.log('DELETE called.  Response: ', response);
        if (response.detail) {
            yield put(apiError(response.detail))
        }
    } catch (err) {
        yield put(apiError(err));
    }
    if (action.dataType === DELETE_SUBSCRIPTION) {
        // get the subscriptions and end from there. 
        console.log("action data token: ", action.data.token)
        yield put(fetchAPI(FETCH_SUBSCRIPTIONS, action.data.token));
    }
}


export function* watchAPIActions() {
    yield takeEvery(FETCH_API, getAPI);
    yield takeEvery(SEND_DATA, sendAPI);
    yield takeEvery(DELETE_API, deleteAPI);
    yield takeEvery(GET_CURRENT_SESSION, getCurrentSession);
}

function* apiSaga() {
    yield all([
        fork(watchAPIActions),
    ]);
}

export default apiSaga;
