import React, { useState, useEffect } from 'react';
import NavBar from './navbar';
import Emoji from './emoji';
import { useDispatch, useSelector } from 'react-redux';
import { RESEND_VERIFICATION_CODE, SEND_VERIFICATION_CODE } from '../redux/api/constants';
import { sendData } from '../redux/actions';
import { useLocation, useNavigate } from 'react-router-dom';

function Confirmation() {
	const [code, setCode] = useState("");
	const user = useSelector(state => state.API.user);
	const error = useSelector(state => state.API.error);
	const message = useSelector(state => state.API.message);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const search = useLocation().search;

	useEffect(() => {
		var qpEmail = new URLSearchParams(search).get('to');
		qpEmail = decodeURIComponent(qpEmail);
		console.log('decoded email: ', qpEmail);
		const qpToken = new URLSearchParams(search).get('token')
		if (qpEmail && qpToken) {
			console.log("sending verification...")
			dispatch(sendData(SEND_VERIFICATION_CODE,
				{ email: qpEmail, code: qpToken, "navigate": navigate }))
		}
	}, [search])

	const verifyEmail = (e) => {
		e.preventDefault();
		dispatch(sendData(SEND_VERIFICATION_CODE,
			{ email: user.email, code: code, "navigate": navigate }));
	}

	const requestNewConfirmation = (e) => {
		e.preventDefault();
		if (user.email) {
			console.log("requesting new auth code for ", user.email);
			dispatch(sendData(RESEND_VERIFICATION_CODE, { email: user.email }));
		} else {
			console.log("I don't know who you are... so I can't request an email")
		}
	}

	return (
		<>
			<NavBar error={error} message={message} />
			<div className="container text-center pt-3">
				<h1><Emoji text="confirm" emoji="🔐" /> Confirmation Code</h1>
				{user.email ?
					<p className="lead">We just sent an email to {user.email}.
						Please check that email and enter the confirmation code below.
						If you don't see the code, check the spam folder.
					</p>
					:
					<p className="lead">If you received an email from us and clicked the verify link, you should be redirected momentarily.
						If you see nothing else,
						please contact <a href="mailto:support@castlerock.ai">support</a>
					</p>

				}
				<form onSubmit={verifyEmail} >
					<div className="col-sm-12 col-md-4 offset-md-4">
						<input className="form-control" type="text" value={code} onChange={(e) => setCode(e.target.value)} />
						<button className="btn btn-primary btn-lg my-2" type="submit">Verify</button>
					</div>
				</form>
				<div>Didn't get confirmation code? <a href="/auth/confirm" onClick={(e) => requestNewConfirmation(e)} >Request a new one.</a></div>
			</div>
		</>

	)
}

export default Confirmation;