import {
    SET_COMMUNICATION_METHOD
} from './actions';

const initState = {
    communicationMethod: "",
}

const signup = (state = initState, action) => {
    switch (action.type) {
        case SET_COMMUNICATION_METHOD:
            console.log("Setting communication method to " + action.payload);
            return { ...state, communicationMethod: action.payload }
        default:
            return { ...state };
    }
};

export default signup;
