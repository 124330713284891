import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPI } from '../redux/actions';
import { PREFERENCES_FETCH, FETCH_SUBSCRIPTIONS } from '../redux/api/constants';
import Subscriptions from './subscriptions';
import Plans from './plans';



function Settings() {
    const dispatch = useDispatch();
    //const preferences = useSelector(state => state.API.preferences);
    const token = useSelector(state => state.API.token);
    const subscriptions = useSelector(state => state.API.subscriptions);

    useEffect(() => {
        dispatch(fetchAPI(PREFERENCES_FETCH, token));
        dispatch(fetchAPI(FETCH_SUBSCRIPTIONS, token));
    }, [token]);

    return (
        <div className="card">
            <div className="card-header text-secondary">Subscription</div>
            <div className="card-body">
                {subscriptions && subscriptions.length > 0 ?
                    <Subscriptions subscriptions={subscriptions} />
                    :
                    <>
                        <p className="text-secondary">You do not have an active subscription.</p>
                        <Plans />
                    </>
                }
            </div>
        </div>
    )
}

export default Settings;