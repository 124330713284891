import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchAPI } from '../redux/actions';
import { FETCH_HOME } from '../redux/api/constants';
import NavBar from './navbar';
import Emoji from './emoji';
import Settings from './settings';
import Search from './search';
import { Link } from 'react-router-dom'
import { toLocalDate } from './localTimeString';
import Footer from './footer';


function PriceSearch({ allDestinations }) {
    return (
        <div className="text-center">
            <h2 className="mt-4 my-2 text-center">
                <Emoji label="fly" emoji="📉" />
                &nbsp;Pricing Data From PDX&nbsp;
            </h2>
            <div className="row my-2">
                <div className="col-md-8 offset-md-2 my-2">
                    <Search destinations={allDestinations} />
                </div>
            </div>
        </div>
    )
}

function NotLoggedInHomePage({ allDestinations }) {
    return (
        <>
            <header className="py-5 ">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1 className="display-4 mt-0">
                            Get <Emoji label="alert" emoji="🚨" />
                            alerts
                            <Emoji label="alert" emoji="🚨" /> when flights from PDX go crazy low.
                        </h1>
                        <p className="display-6 lead text-green">
                            For <span className="fw-bold">$4.20/mo</span> you can save up to <span className="fw-bold text-success">85% on your next flight</span>
                        </p>
                        <div className="display-6 lead mt-5">
                            <Link to="/signup" className="btn btn-lg btn-outline-primary me-2" >Sign up Now</Link>
                        </div>
                    </div>
                </div>
            </header>
            <header className="pt-5 border-top">
                <div className="row">
                    <div className="col-md-12">
                        <PriceSearch allDestinations={allDestinations} />
                    </div>
                </div>
            </header>
            <header className="pt-5">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h1 className="mt-3">
                            <Emoji label="wizard" emoji="🧙‍♂️" />
                            How It Works
                        </h1>
                        <ul className="lead list-unstyled">
                            <li><Emoji label="" emoji="👍🏼" />&nbsp;Sign up for alerts</li>
                            <li><Emoji label="text" emoji="📲" />&nbsp;
                                Get Email/Text from us when a deal comes available
                            </li>
                            <li ><Emoji label="book" emoji="🔖" />&nbsp;
                                Book Your flight
                            </li>
                            <li ><Emoji label="takeoff" emoji="🛫" />&nbsp;
                                Live an amazing life
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </>
    )


}

function LoggedInHomePage({ allDestinations }) {
    const user = useSelector(state => state.API.user);
    return (
        <>
            <div className="row mt-3">
                <div className="col-md-12">
                    <PriceSearch allDestinations={allDestinations} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-3">
                        <div className="card-header text-secondary">Profile</div>
                        <div className="card-body">
                            <h5 className="card-title text-primary">{user.email}</h5>
                            <p className="card-text text-secondary">Joined {toLocalDate(user.created_at)}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <Settings />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-3">
                        <div className="card-header text-secondary">Radar</div>
                        <div className="card-body">
                            <div className="card-title text-primary">Coming Soon!</div>

                            <ul className="card-text text-secondary">
                                <li><Emoji label="world" emoji="🗺" /> Set Notifications for specific destinations</li>
                                <li><Emoji label="calendar" emoji="📆" /> Set Notifications for travel windows</li>
                                <li><Emoji label="phone" emoji="📲" /> SMS Alerts</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


function Home() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAPI(FETCH_HOME));
    }, [dispatch])

    const allDestinations = useSelector(state => state.API.allDestinations);
    const error = useSelector(state => state.API.error);
    const message = useSelector(state => state.API.message);
    //const isLoading = useSelector(state => state.API.loading);
    const isAuthenticated = useSelector(state => state.API.isAuthenticated);

    return (
        <>
            <NavBar selected="home" error={error} message={message} />
            <div className="container min-vh-100">
                {/*isLoading ? <div className="text-center">Loading...</div> :*/}
                {isAuthenticated ?
                    <LoggedInHomePage allDestinations={allDestinations} /> :
                    <NotLoggedInHomePage allDestinations={allDestinations} />
                }
            </div>
            <Footer />
        </>
    );
}

export default Home;
