import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import NavBar from './navbar';
import { useNavigate } from 'react-router-dom';
import {
	CardElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import Emoji from './emoji';
import { apiError, sendData } from '../redux/actions';
import { DID_PURCHASE_SUBSCRIPTION } from '../redux/api/constants';

function Subscribe() {
	const dispatch = useDispatch();
	const token = useSelector(state => state.API.token);
	const navigate = useNavigate()
	const error = useSelector(state => state.API.error);
	const subscriptionData = useSelector(state => state.API.subscriptionData);
	const [paymentIntent, setPaymentIntent] = useState();
	const [name, setName] = useState('');

	// Initialize an instance of stripe.
	const stripe = useStripe();
	const elements = useElements();

	if (!stripe || !elements) {
		// Stripe.js has not loaded yet. Make sure to disable
		// form submission until Stripe.js has loaded.
		return '';
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const cardElement = elements.getElement(CardElement)
		// Use card Element to tokenize payment details
		let { error, paymentIntent } = await stripe.confirmCardPayment(subscriptionData.clientSecret, {
			payment_method: {
				card: cardElement,
				billing_details: {
					name: name,
				}
			}
		});

		if (error) {
			// show error and collect new card details.
			dispatch(apiError(error.message));
			return;
		}
		dispatch(apiError(null));
		setPaymentIntent(paymentIntent);
	}

	if (paymentIntent && paymentIntent.status === 'succeeded') {
		dispatch(sendData(DID_PURCHASE_SUBSCRIPTION,
			{ paymentIntent: paymentIntent, token: token, "navigate": navigate }
		));
	}

	return (
		<>
			<NavBar selected="home" error={error} />
			{console.log("subscription data: ", subscriptionData)}
			<div className="container pt-md-1 pb-md-4">
				<div className="row">
					<div className="col-md-6 offset-md-3 mt-3">
						<div className="card">
							<div className="card-body text-secondary">
								<form onSubmit={handleSubmit}>
									<div className="mb-3">
										<p>
											<Emoji text="yay!" emoji="🎉" />
											Subscribe and receive alerts for incredible flight deals! Cancel at any time.
										</p>
									</div>
									<div className="mb-3">
										<label htmlFor="name" className="form-label">Full Name</label>
										<input type="text" className="form-control" id="name" aria-describedby="nameHelp" value={name} onChange={(e) => setName(e.target.value)} />
									</div>
									<div className="mb-3">
										<CardElement />
									</div>
									<button type="submit" className="btn btn-lg btn-primary float-end">Subscribe</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Subscribe;