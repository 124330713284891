import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { setToken } from '../redux/actions';
import { dismissMessage } from '../redux/actions';

function UserDropDown({ user }) {
	const dispatch = useDispatch();
	const signout = (e) => {
		e.preventDefault();
		console.log("signout!")
		dispatch(setToken(null));
	}
	return (
		<li className="nav-item dropdown">
			<button className="btn btn-outline-info nav-link dropdown-toggle" id="userDropDownMenu"
				data-bs-toggle="dropdown" aria-expanded="false" aria-label="Toggle navigation">
				{user.email}
			</button>
			<ul className="dropdown-menu" aria-labelledby="userDropdownMenu">
				<li><Link to="/signout" className="dropdown-item" onClick={signout}>Logout</Link></li>
			</ul>
		</li>
	)
}


function NavBar({ selected, error, message }) {
	const user = useSelector(state => state.API.user);
	const isAuthenticated = useSelector(state => state.API.isAuthenticated);
	const blogEnabled = false;
	const dispatch = useDispatch();

	const dissmissMessage = (e) => {
		e.preventDefault();
		dispatch(dismissMessage());
	}

	return (
		<>
			<nav className="navbar navbar-expand-md navbar-light" style={{ 'backgroundColor': '#fff' }}>
				<div className="container-fluid">
					<a className="navbar-brand" href="/">
						<img src="/images/logo-light.png" alt="pdx to the world" height="24" className="d-inline-block align-text-top" />
					</a>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarToggler">
						<ul className="navbar-nav ml-auto mb-2 mb-lg-0 d-flex">
							<li className="nav-item">
								<Link to="/today-deals" className={selected === "today" ? "nav-link active" : "nav-link"} >Today's Deals</Link>
							</li>
							<li className="nav-item">
								<Link to="/about" className={selected === "about" ? "nav-link active" : "nav-link"} > About</Link>
							</li>
							<li className="nav-item">
								<Link to="/privacy" className={selected === "privacy" ? "nav-link active" : "nav-link"} > Privacy</Link>
							</li>
							{blogEnabled &&
								<li className="nav-item">
									<Link to="/pdx" className={selected === "pdx" ? "nav-linnk active" : "nav-link"} >PDX Airport Guide</Link>
								</li>
							}
						</ul>
						<ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex">
							{isAuthenticated && Object.entries(user).length > 0 ?
								<>
									<UserDropDown user={user} />
								</>
								:
								<>
									<li className="nav-item">
										<Link to="/signin" className={selected === "signin" ? "nav-link active" : "nav-link"}>Sign In</Link>
									</li>
									<li className="nav-item">
										<Link to="/signup" className={selected === "signup" ? "btn btn-primary active" : "btn btn-primary "}>Sign Up</Link>
									</li>
								</>


							}
						</ul>
					</div>


				</div>
			</nav >
			{
				error &&
				<div className="alert alert-danger text-center">
					{error.toString()}
				</div>
			}
			{
				message &&
				<div className="alert alert-info text-center">
					{message.toString()}
					<button type="button" className="btn-close float-end" aria-label="Close" onClick={dissmissMessage}></button>
				</div>
			}
		</>
	);
}

export default NavBar;