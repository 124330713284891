import React, { useState } from 'react';
import NavBar from './navbar';

function NotFound() {
    // Declare a new state variable, which we'll call "count"  const [count, setCount] = useState(0);
    const [count, setCount] = useState(0);
    return (
        <>
            <NavBar />
            <div className="container">
                Hmm, that path could not be found. Sorry.  Maybe you'd like to play with this clicker button?
                <br />
                <button className="my-2 btn btn-primary" onClick={() => setCount(count + 1)}>
                    Click me
                </button>
                <p>You clicked {count} times</p>
            </div>

        </>
    );
}
export default NotFound;