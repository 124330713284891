import React, { useState } from 'react';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { sendData } from '../redux/actions';
import { RESET_PASSWORD } from '../redux/api/constants';
import { Link, useNavigate } from 'react-router-dom';
import NavBar from './navbar';
import Emoji from './emoji';

function Forgot() {
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const error = useSelector(state => state.API.error);
	const message = useSelector(state => state.API.message);
	const isAuthenticated = useSelector(state => state.API.isAuthenticated);
	if (isAuthenticated) {
		navigate("/");
	}

	const validateAndSubmit = (e) => {
		e.preventDefault();
		setEmailError(null)
		if (!validator.isEmail(email)) {
			setEmailError('Please enter a valid email address.')
			return;
		}
		dispatch(sendData(RESET_PASSWORD, { email: email }));
	}

	return (
		<>
			<NavBar error={error} message={message} />
			<div className="container">
				<form onSubmit={validateAndSubmit}>
					<fieldset>
						<legend className="text-center">Reset Your Password</legend>
						<div className="mb-3 col-md-4 offset-md-4">
							<input
								className={emailError ? "form-control form-control-lg is-invalid" :
									"form-control form-control-lg"}
								type="text"
								placeholder="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								aria-label="enter email" />
							<div className="invalid-feedback">
								{emailError}
							</div>
						</div>
						<div className="mt-3 text-center" >
							<button type="submit" className="btn btn-lg btn-primary mx-auto">Reset</button>
						</div>
						<div className="mt-3 text-center text-muted" >
							<Emoji text="no account" emoji="😢" /> No Account? <Link to="/signup" >Sign up!</Link> <Emoji text="sign up" emoji="😃" />
							&nbsp;| Have an account? <Link to="/signin">Sign In!</Link>
						</div>
					</fieldset>
				</form>
			</div>
		</>
	)
}

export default Forgot;