import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import config from './config'

/* Stripe */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(config.STRIPE_PROMISE_KEY);

ReactDOM.render(
  <Provider store={configureStore()}>
    <Router>
      <Elements stripe={stripePromise} >
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Elements>
    </Router>
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();
