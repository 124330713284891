import React from 'react';
import NavBar from './navbar';
import Emoji from './emoji';
import Footer from './footer';



function Privacy() {

    return (
        <>
            <NavBar selected="privacy" />
            <div className="container min-vh-100">
                <div className="lead mt-3">
                    <h1><Emoji label="hi" emoji="🕵️‍♀️" /> Tracking</h1>
                    I didn't install any tracking software on this website.
                    <br />
                    There's no Facebook Ads, no Google Ads,
                    No Google Analytics, Google Tag Manager, etc.
                    I don't use those guys. If you want to be tracked by them, you can use our competitors.
                    <br />
                    I will look in my logs from time to time to see IP addresses, and data flow, but for the most part,
                    I don't know who you are.
                </div>
                <div className="lead mt-3">
                    <h1><Emoji label="hi" emoji="‍️🗄" /> Your Data</h1>
                    When you give me your email for my mailing list, I keep it in my database, backed up, encrypted
                    and I don't sell it.  I only send you emails or SMS messages if you want them.  If not, you can unsubscribe and
                    we are good.  I treat you the way I want you to treat my information.
                    <br />
                </div>
                <div className="lead mt-3">
                    <h1><Emoji label="contact" emoji="️️🥾" /> Contact</h1>
                    If you have any issues, or other questions, send me an <a href="mailto:vallard@castlerock.ai">email!</a>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy;